
import { mapGetters } from 'vuex'
export default {
    name: 'Theme2DesktopJackpotInterval',
    computed: {
        ...mapGetters({
            jackpot: 'cms/jackpot',
            jackpotVal: 'cms/jackpotVal',
        }),
    },
}
